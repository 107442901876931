import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Container from "layout/Container"

import useProofOfPaymentIcon from "./hooks/useProofOfPaymentIcon"
import styles from "./utils/proofOfPayment.module.scss"

const ProofOfPaymentInvalid = () => {
  const data = useProofOfPaymentIcon()
  return (
    <Container isCentered>
      <div className={classNames("columns", styles["column"])}>
        <div className="column is-4-desktop is-12-mobile">
          <figure className={classNames("image mx-3")}>
            <Img
              fluid={data.email.childImageSharp.fluid}
              alt={`Proof of Payment Complete`}
              className={styles["image"]}
            />
          </figure>
        </div>
        <div className="column">
          <Fragment>
            <p className="mb-2">
              For inquiries, please contact us at careplus@medgrocer.com or
              visit our{" "}
              <Link
                to="/frequently-asked-questions"
                className="has-text-weight-bold"
              >
                Frequently Asked Questions
              </Link>
              .
            </p>
          </Fragment>
        </div>
      </div>
      <center>
        <Link to="/" className="mt-2 button is-primary is-medium">
          Finish
        </Link>
      </center>
    </Container>
  )
}

export default ProofOfPaymentInvalid
